import http from '@/utils/http'


// 找竞品
export function get_company_list(data, token) {
  return http({
    url: '/u-search/ac-get_company_list',
    transformRequest: [(data) => {
      return data
    }],
    header: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data: JSON.stringify(data),
    cancelToken: token || null
  })
}
// 找广告
export function get_add_list(data) {
  return http({
    url: '/u-adsearch/ac-get_ad_list',
    transformRequest: [(data) => {
      return data
    }],
    header: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data: JSON.stringify(data)
  })
}

//按公司找竞品（地区）
export function get_ad_compete_list_v2(data) {
  return http({
    url: '/u-adsearch/ac-get_ad_compete_list_v2',
    transformRequest: [(data) => {
      return data
    }],
    header: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data: JSON.stringify(data)
  })
}

// 监测关键词
export function add_monitor(data) {
  return http({
    url: '/u-base/ac-add_monitor',
    method: 'get',
    params: data
  })
}

// 取消监测关键词
export function cancel_monitor(data) {
  return http({
    url: '/u-base/ac-cancel_monitor',
    method: 'get',
    params: data
  })
}

//导出数据
export function ge_export_task(data) {
  return http({
    url: '/u-base/ac-ge_export_task',
    transformRequest: [(data) => {
      return data
    }],
    header: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data: JSON.stringify(data)
  })
}

//可导出数据查询
export function get_last_export_cnt(data) {
  return http({
    url: '/u-base/ac-get_last_export_cnt',
    method: 'get',
    params: data
  })
}

//搜索页公司详情
export function get_ad_compete_list(data) {
  return http({
    url: '/u-adsearch/ac-get_ad_compete_list',
    method: 'get',
    params: data
  })
}

// 获取默认公司
export function get_val_by_key(data) {
  return http({
    url: '/u-base/ac-get_val_by_key',
    method: 'get',
    params: data
  })
}

//按公司找竞品切换公司-首页专用
export function get_ad_compete_list_search_index(data) {
  return http({
    url: '/u-adsearch/ac-get_ad_compete_list_search_index',
    method: 'get',
    params: data
  })
}

//按公司找竞品切换公司
export function get_ad_compete_list_search(data) {
  return http({
    url: '/u-adsearch/ac-get_ad_compete_list_search',
    method: 'get',
    params: data
  })
}

// 加入收藏
export function add_collect(data) {
  return http({
    url: '/u-base/ac-add_collect',
    method: 'get',
    params: data
  })
}
// 取消收藏
export function cancel_collect(data) {
  return http({
    url: '/u-base/ac-cancel_collect',
    method: 'get',
    params: data
  })
}

// 设为默认公司
export function set_val_by_key(data) {
  return http({
    url: '/u-base/ac-set_val_by_key',
    method: 'get',
    params: data
  })
}

//创建刷新任务
export function ge_refresh_task(data) {
  return http({
    url:'/u-base/ac-ge_refresh_task',
    method: 'get',
    params: data
  })
}

//查关键词列表数据获取
export function get_performance_list(data) {
  return http({
    url:'/u-wordperformance/ac-get_performance_list',
    method: 'get',
    params: data
  })
}

//查关键词头部数据获取
export function get_report(data) {
  return http({
    url:'/u-wordperformance/ac-get_report',
    method: 'get',
    params: data
  })
}
