<!--导出类型选择弹窗-->
<template>
    <div class="export_dialog">
        <el-dialog
            :visible.sync="visible"
            :before-close="handleClose">
            <template #title>
                <div class="title_text">导出数据</div>
                <div class="sub_text">当前共找到 <span class="count">{{allCnt}}</span> 条结果，请选择导出方案</div>
            </template>
            <div class="dialog_content">
                <div class="type_selector">
                    <div class="type_item" :class="export_type == 'vip'?'selected':''" @click="export_type = 'vip'">
                        <div class="type_name">
                            <span v-if="vipType == 20">标准版会员特权</span>
                            <span v-if="vipType == 30">快速导出</span>
                            <img v-if="vipType == 20" src="../../assets/img/dialog/vip_icon.png"/>
                            <img class="svip_img" v-if="vipType == 30" src="../../assets/img/dialog/fast_export.png"/>
                        </div>
                        <div class="export_cnt">{{fastCnt}}条</div>
                        <div class="surplus_cnt">
                            <span>今天还可以免费导出<span class="count">{{surplus}}</span>次</span>
                        </div>
                    </div>
                    <div class="type_item" :class="export_type == 'svip'?'selected':''" @click="export_type = 'svip'">
                        <div class="type_name">
                            <span>至尊版会员特权</span>
                            <img v-if="vipType == 20" src="../../assets/img/dialog/svip_icon.png"/>
                            <img class="svip_img" v-if="vipType == 30" src="../../assets/img/dialog/total_export.png"/>
                        </div>
                        <div class="export_cnt">{{totalCnt}}条</div>
                        <div v-if="export_type == 'svip' && vipType == 30" class="surplus_cnt">
                            <span>今天还可以免费导出<span class="count">{{surplus}}</span>次</span>
                        </div>
                        <div class="surplus_cnt" v-else>
                            <span>可导出{{allCnt > totalCnt? totalCnt+'条':'全部数据'}}<span v-if="totalCnt > 10000">（文件格式CSV）</span></span>
                        </div>
                    </div>
                </div>
                <div style="text-align: center" v-if="export_type == 'vip'">
                    <span @click="handleExport" class="export_btn">立即导出</span>
                </div>
                <div style="text-align: center" v-if="export_type == 'svip'&&vipType == 30">
                    <span @click="handleExport" class="export_btn">立即导出</span>
                </div>
                <div class="upgrade_svip" v-if="export_type == 'svip'&&vipType != 30">
                    <img src="../../assets/img/dialog/service_icon.png"/>
                    <span>请联系您的客户经理升级至尊版</span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
  export default {
    name: "export_dialog",
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      width: String,
      allCnt: Number,
      surplus: null,
      fastLimit: Number,
      totalLimit: Number,
    },
    data() {
      return {
        export_type: 'vip',
      }
    },
    computed: {
      vipType() {
        return this.$store.state.userInfo.vip_type;
      },
      fastCnt() {
        let cnt = null;
        if (this.allCnt<= this.fastLimit) {
          cnt = this.allCnt;
        } else {
          cnt = this.fastLimit
        }
        return cnt;
      },
      totalCnt() {
        let cnt = null;
        if (this.allCnt<= this.totalLimit) {
          cnt = this.allCnt;
        } else {
          cnt = this.totalLimit
        }
        return cnt;
      }
    },
    methods: {
      handleClose() {
        this.$emit("beforeClose");
      },
      handleExport() {
        let count = this.export_type == 'vip'? this.fastCnt:this.totalCnt
        this.$emit("handleExport", count);
      }
    }
  }
</script>

<style lang="scss">
.export_dialog {
    .el-dialog {
        width: 700px;
        border-radius: 5px;
        overflow: hidden;
        .el-dialog__headerbtn .el-dialog__close {
            color: rgba(0, 0, 0, 0.3);
        }
        .el-dialog__header {
            background: url("../../assets/img/dialog/export_bg.png");
            background-size: cover;
            padding: 36px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            .title_text {
                font-size: 24px;
                color: #8f5017;
                font-weight: bold;
            }
            .sub_text {
                font-size: 18px;
                color: #b47c49;
                margin-top: 16px;
                .count {
                    color: #985111;
                }
            }
        }
        .el-dialog__body {
            padding: 0;
            .dialog_content {
                padding: 30px;
                .type_selector {
                    display: flex;
                    justify-content: space-around;
                    .type_item {
                        width: 40%;
                        padding: 20px 18px 26px 20px;
                        background-color: #ffffff;
                        border: solid 1px #ebebeb;
                        border-radius: 6px;
                        cursor: pointer;
                        .type_name {
                            display: flex;
                            align-items: center;
                            flex-direction: row-reverse;
                            font-size: 16px;
                            font-weight: bold;
                            color: #8f5017;
                        }
                        .export_cnt {
                            margin-top: 8px;
                            font-size: 24px;
                            font-weight: bold;
                            color: #444444;
                        }
                        .surplus_cnt {
                            font-size: 16px;
                            color: #999999;
                            margin-top: 13px;
                            .count {
                                color: #f3a649;
                            }
                        }
                        img {
                            width: 24px;
                            height: 27px;
                            margin-right: 8px;
                        }
                        .svip_img {
                            width: 29px;
                            height: 29px;
                        }
                    }
                    .selected {
                        border: solid 2px #e4c584;
                        background-color: #fff9ef;
                        position: relative;
                        &:after {
                            display: inline-block;
                            content: "";
                            position: absolute;
                            bottom: -1px;
                            right: -1px;
                            width: 32px;
                            height: 32px;
                            background: url("../../assets/img/dialog/footer_icon.png");
                            background-size: cover;
                        }
                    }
                }
                .export_btn {
                    display: inline-block;
                    font-size: 16px;
                    color: #ffffff;
                    background-color: #ff7e00;
                    border-radius: 4px;
                    padding: 13px 50px;
                    margin-top: 37px;
                    margin-bottom: 8px;
                    cursor: pointer ;
                }
                .upgrade_svip {
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    color: #333333;
                    margin-top: 25px;
                    margin-bottom: 23px;
                    img {
                        width: 38px;
                        height: 38px;
                        margin-right: 10px;
                        margin-left: 3%;
                    }
                }
            }
        }
    }
}
</style>
