<template>
    <div class="find_word">
        <div class="search_box" id="top">
            <div class="input_box">
                <div class="input_shadow">
                    <el-input
                        type="text"
                        ref="input"
                        v-model="search.keywords"
                        @keyup.enter.native="handleSearch"
                        @input="closeDialog"
                        @focus="showHistory"
                        @blur="hiddenHistory"
                        placeholder="请输入关键词"
                        maxlength="100">
                        <template #append style="width: 48px">
                            <div class="box" @click="handleSearch">
                                <img src="@/assets/img/search/icon_search.png" alt="" width="18px" height="18px">
                            </div>
                        </template>
                    </el-input>

                    <div class="empty_input" v-if="showEmptyInput">
                        <img src="@/assets/img/companyDetails/icon_exclamation.png" alt="">
                        <span>你还未输入关键词</span>
                    </div>

                    <div class="search_history" v-if="showInputHistory">
                        <div class="search_item" v-for="(item,i) in searchHistoryList" :key="i" @click="selectHistory(item)">{{item}}</div>
                    </div>
                </div>

            </div>
            <div class="selections">
                <div class="select_item" style="margin-bottom: 32px">
                    <span class="title">关键词特点：</span>
                    <span class="option" :class="search.characteristic == ''?'selected':''" @click="changeCharacteristic('')">全部</span>
                    <span class="option" :class="search.characteristic == '热搜'?'selected':''" @click="changeCharacteristic('热搜')">热搜<span class="count">{{allCnt.high_label}}</span></span>
                    <span class="option" :class="search.characteristic == '黑马'?'selected':''" @click="changeCharacteristic('黑马')">黑马<span class="count">{{allCnt.balck_label}}</span></span>
                    <span class="option" :class="search.characteristic == '无特点'?'selected':''" @click="changeCharacteristic('无特点')">无特点<span class="count">{{allCnt.no_label}}</span></span>
                </div>
                <div class="select_item" style="margin-bottom: 32px">
                    <span class="title">竞价激烈程度：</span>
                    <span class="option" :class="search.level == ''?'selected':''" @click="changeLevel('')">全部</span>
                    <span class="option" :class="search.level == '高'?'selected':''" @click="changeLevel('高')">高<span class="count">{{allCnt.hight}}</span></span>
                    <span class="option" :class="search.level == '中'?'selected':''" @click="changeLevel('中')">中<span class="count">{{allCnt.middle}}</span></span>
                    <span class="option" :class="search.level == '低'?'selected':''" @click="changeLevel('低')">低<span class="count">{{allCnt.low}}</span></span>
                </div>
                <div class="select_item">
                    <span class="title">搜索指数：</span>
                    <span class="option" :class="search.exponent == ''?'selected':''" @click="changeExponent('')">全部</span>
                    <span class="option" :class="search.exponent == '1'?'selected':''" @click="changeExponent('1')">有<span class="count">{{allCnt.has_idx}}</span></span>
                    <span class="option" :class="search.exponent == '0'?'selected':''" @click="changeExponent('0')">无<span class="count">{{allCnt.no_idx}}</span></span>
                </div>
            </div>
        </div>
        <div class="search_list">
            <div class="header">
                <span class="cnt">共找到<span style="color: #ef3819;">{{listConfig.real_cnt > 100000? '100000+':listConfig.real_cnt}}</span>个关键词</span>
                <myButton type="default" width="100" height="33" @click="exportList" class="button">
                    <img slot="img" src="@/assets/img/companyDetails/icon_export.png" alt="" width="14px" height="14px">
                    <span slot="text">立即导出</span>
                </myButton>
            </div>
            <el-table
                :data="allData.rows"
                v-loading="loading"
                empty-text="暂无相关数据"
                @sort-change="sortChange"
                :header-cell-style="{background:'#f8fafc', color: '#666666'}">
                <el-table-column
                    min-width="90"
                    label="关键词">
                    <template slot-scope="scope">
                        <span :title="scope.row.word" class="two_line">{{scope.row.word}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    min-width="60"
                    align="center"
                    prop="idx_lable"
                    label="关键词特点">
                    <template slot-scope="scope">
                        <el-tooltip  effect="light" width="100" placement="right-start" content="竞争度低且搜索量较高的关键词" v-if="scope.row.idx_lable == '黑马'">
                            <div class="hm">黑马</div>
                        </el-tooltip>
                        <el-tooltip  effect="light" width="100" placement="right-start" content="搜索量高或点击量高的关键词" v-if="scope.row.idx_lable == '热搜'">
                            <div class="rs">热搜</div>
                        </el-tooltip>
                        <div v-if="scope.row.idx_lable == '无特点'">无特点</div>
                    </template>
                </el-table-column>
                <el-table-column
                    min-width="60"
                    align="center"
                    label="竞价激烈程度">
                    <template slot-scope="scope">
                        <span>{{scope.row.idx_competition ||'数据更新中'}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    min-width="60"
                    align="center"
                    sortable="custom"
                    prop="pc_pv"
                    label="PC日搜索量">
                    <template slot-scope="scope">
                        <span>{{scope.row.idx_pc_pv < 5?'<5':scope.row.idx_pc_pv}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    min-width="70"
                    align="center"
                    sortable="custom"
                    prop="mobile_pv"
                    label="移动日搜索量">
                    <template slot-scope="scope">
                        <span>{{scope.row.idx_mobile_pv < 5?'<5':scope.row.idx_mobile_pv}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    min-width="60"
                    align="center"
                    sortable="custom"
                    prop="week_index"
                    label="搜索指数">
                    <template slot-scope="scope">
                        <span>{{scope.row.idx || '无'}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    min-width="60"
                    align="center"
                    label="SEM参考价格">
                    <template slot-scope="scope">
                        <span>{{scope.row.idx_bid == 0?'价格统计中':scope.row.idx_bid}}</span>
                    </template>
                </el-table-column>
<!--                <el-table-column-->
<!--                    min-width="60"-->
<!--                    prop="company_cnt"-->
<!--                    align="center"-->
<!--                    label="竞价公司">-->
<!--                    <template slot-scope="scope">-->
<!--                        <span class="to_ad">-->
<!--                            {{scope.row.company_cnt == '0'?'数据统计中':scope.row.company_cnt}}-->
<!--                        </span>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column
                    min-width="90"
                    prop="find_time"
                    label="最近发现时间">
                </el-table-column>
                <el-table-column
                    min-width="200"
                    label="最近推广记录">
                    <template slot-scope="scope">
                        <div v-if="!scope.row.title || !scope.row.land_url || !scope.row.domain">数据更新中</div>
                        <template v-else>
                            <div :title="scope.row.title" class="cell_title two_line" @click="$C.jump(scope.row.land_url, 3187)">{{scope.row.title}}</div>
                            <div  :title="scope.row.domain" class="cell_link" v-if="scope.row.domain">
                                <span class="link">{{scope.row.domain}}</span>
                                <span class="platform">{{$C.platformTurn(parseInt(scope.row.platform))}}</span>
                            </div>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column
                    width="110"
                    label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" v-if="!scope.row.monitor" @click="handelMonitor(scope.row.word,2, 'word')">监测关键词</el-button>
                        <el-button type="text" disabled v-if="scope.row.monitor">监测中</el-button>
                        <el-button type="text" v-if="scope.row.monitor" @click="cancelMonitor(scope.row.word,2, 'word')">取消</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <page :config="listConfig" @pageChange="pageChange" @limitChange="limitChange"></page>
        </div>

        <common_dialog
            @beforeClose="notVipShow = false"
            :visible.sync="notVipShow"
            :text="commonDialogText"
            width="40%">
        </common_dialog>

        <export_dialog
            @beforeClose="dialogVisible = false"
            @handleExport="handleExport"
            :visible.sync="dialogVisible"
            :all-cnt="parseInt(exportCnt)"
            :surplus="surplus"
            :fast-limit="10000"
            :total-limit="100000"
            width="60%">
        </export_dialog>
    </div>
</template>

<script>
  import PinganAna from "pingansec-vue-ana";
  import * as api from "@/api/search"
  import myButton from "../../../components/myButton/myButton";
  import common_dialog from "../../../components/dialogs/common_dialog";
  import export_dialog from "../../../components/dialogs/export_dialog";

  export default {
    name: "findWord",
    components: {
      myButton,
      common_dialog,
      export_dialog
    },
    data() {
      return {
        allData: {},
        allCnt: {},
        showEmptyInput: false,
        showInputHistory: false,
        searchHistoryList: [],
        search: {
          keywords: '',
          characteristic: '',
          level: '',
          exponent: '',
          sort_key: '',
          sort_type: ''
        },
        loading: false,
        listConfig: {
          page: 1,
          limit: 10,
          total: null,
          sizes: [10, 20, 30, 40, 50],
          count: 5,
          real_cnt: null
        },
        notVipShow: false, //非vip导出提示弹窗
        commonDialogText: '', //弹窗提示内容
        dialogVisible: false, //导出列表确认弹窗
        surplus: null, //剩余导出次数
        exportCnt: null, //导出数量
      }
    },
    created() {
      if(this.$route.query.word) {
        this.search.keywords = this.$route.query.word
      }
    },
    updated() {
      var dom = document.getElementsByClassName('cell_link')
      if(dom.length) {
        setTimeout(_=>{
          var width = (dom[0].clientWidth - 65) + 'px'
          for (var i = 0; i < dom.length; i++) {
            dom[i].children[0].style.setProperty('--max-width',width)
          }
        },100)
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      pageChange(val) {
        this.listConfig.page = val
        this.init()
        document.getElementById("top").scrollIntoView()
      },
      limitChange(val) {
        this.listConfig.page = 1;
        this.listConfig.limit = val;
        this.init()
        document.getElementById("top").scrollIntoView()
      },
      changeLevel(item) {
        this.search.level = item;
        this.init()
      },
      changeExponent(item) {
        this.search.exponent = item;
        this.init()
      },
      changeCharacteristic(item) {
        this.search.characteristic = item;
        this.init()
      },
      sortChange(column) {
        if(column.order=='ascending') {
          this.search.sort_type = 'asc'
        } else {
          this.search.sort_type = 'desc'
        }
        this.search.sort_key = column.prop
        this.init();
      },
      exportList() {
        if (this.$store.state.userInfo.vip_type == 10) {
          this.notVipShow = true;
          this.commonDialogText = '当前为试用版会员，标准版会员可导出数据，请联系您的客户经理开通';
        } else if(this.$store.state.userInfo.vip_type == 5) {
          this.notVipShow = true;
          this.commonDialogText = '当前为基础版会员，标准版会员可导出数据，请联系在线客服开通';
        } else {
          api.get_last_export_cnt().then(res => {
            if(res.data.result_code==0) {
              this.surplus = res.data.data.total-res.data.data.used;
              this.exportCnt = this.listConfig.real_cnt
              this.dialogVisible = true
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        }
      },
      handleExport(count) {
        let condition = {
          word: this.search.keywords,
          label: this.search.characteristic,
          competition: this.search.level,
          idx: this.search.exponent,
          page: this.listConfig.page,
          limit: this.listConfig.limit,
          sort_key: this.search.sort_key,
          sort_type: this.search.sort_type
        }
        let params = {
          export_type: '9',
          export_limit: count,
          export_condition: JSON.stringify(condition)
        }
        api.ge_export_task(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '导出任务创建成功！'
            })
            this.dialogVisible = false;
            const { href } = this.$router.resolve({
              name: "export",
            });
            setTimeout(() => {
              window.open(href, '_blank');
            },500)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      handelMonitor(keyword,type) {
        let params = {
          keyword,
          type
        }
        api.add_monitor(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '监测成功！还可监测' +res.data.data.monitor_company_last +
                '家竞品公司，'+res.data.data.monitor_word_last+'个关键词'
            })
            this.init()
          } else {
            if(res.data.result_code == 120011 && (this.$store.state.userInfo.vip_type == 20 || this.$store.state.userInfo.vip_type == 10)) {
              this.commonDialogText = '您的监测数量已达上限，至尊版可监测50家竞品/100个关键词，如需监测更多，请联系您的客户经理开通'
              this.notVipShow = true;
            } else if(res.data.result_code == 120011 && this.$store.state.userInfo.vip_type == 5) {
              this.commonDialogText = '监测已达上限。标准版可监测竞品公司10家/关键词20个，请联系在线客服开通'
              this.notVipShow = true;
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          }
        })
      },
      cancelMonitor(keyword,type) {
        let params = {
          keyword,
          type
        }
        api.cancel_monitor(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '已取消监测！'
            })
            this.init()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      closeDialog(word) {
        if(word) {
          this.showEmptyInput = false;
        }
      },
      showHistory() {
        if(!this.search.keywords) {
          this.searchHistoryList = Array.from(new Set(JSON.parse(localStorage.getItem('search_history_wd')))).slice(0,5)
          this.showInputHistory = true
        }
      },
      hiddenHistory() {
        setTimeout(() => {
          this.showInputHistory = false
        },500)
      },
      selectHistory(word) {
        this.search.keywords = word;
        this.showInputHistory = false
        this.handleSearch();
      },
      handleSearch() {
        if(!this.search.keywords) {
          this.showEmptyInput = true;
          this.time = setTimeout(() => {
            this.showEmptyInput = false;
          }, 3000)
          return
        }
        this.searchHistoryList.unshift(this.search.keywords)
        localStorage.setItem('search_history_wd', JSON.stringify(this.searchHistoryList))
        this.showInputHistory = false
        this.clearSelection()
      },
      clearSelection() {
        this.search.characteristic = ''
        this.search.level = ''
        this.search.exponent = '';
        this.search.sort_key = '';
        this.search.sort_type = '';
        this.listConfig.page = 1;
        this.listConfig.limit = 10;
        this.init()
      },
      getCnt() {
        let params = {
          word: this.search.keywords,
        }
        let p = new Promise((resolve, reject) => {
          api.get_report(params).then(res => {
            if(res.data.result_code == 0) {
              this.allCnt = res.data.data
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      init() {
        this.getCnt();
        let params = {
          word: this.search.keywords,
          label: this.search.characteristic,
          competition: this.search.level,
          idx: this.search.exponent,
          page: this.listConfig.page,
          limit: this.listConfig.limit,
          sort_key: this.search.sort_key,
          sort_type: this.search.sort_type
        }
        this.loading = true;
        let p = new Promise((resolve, reject) => {
          api.get_performance_list(params).then(res => {
            if(res.data.result_code == 0) {
              if(this.$store.state.userInfo.vip_type == 5 && res.data.data.limit_search_bool) {
                this.notVipShow = true;
                this.commonDialogText = '基础版每天可免费搜索3次。标准版无搜索限制，请联系在线客服开通';
              }
              if(this.search.keywords) {
                PinganAna.ready(() => {
                  PinganAna.userClickLog(
                    '"Position":"搜索"',
                    '{"searchType":"查关键词","pageName":"'+this.$route.name+'",'+JSON.stringify(params)+'}',
                    '【AI竞投】系统',
                    this.$store.state.userInfo.vip_type == 5?3:this.$store.state.userInfo.vip_type == 10?1:2,
                    '--',
                    this.$store.state.userInfo.account
                  )
                })
              }
              this.allData = res.data.data
              this.listConfig.total = res.data.data.cnt
              this.listConfig.real_cnt = res.data.data.real_cnt
              this.loading = false;
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      }
    }
  }
</script>

<style scoped lang="scss">
.find_word {
    .hm {
        display: inline-block;
        padding: 5px 9px;
        border-radius: 3px;
        border: solid 1px #ebeff2;
        background-color: #f8fafc;
        font-size: 14px;
        line-height: 14px;
        color: #333333;
        cursor: pointer;
    }
    .rs {
        display: inline-block;
        padding: 5px 9px;
        background-color: #faf4f3;
        border-radius: 3px;
        border: solid 1px #ece4e3;
        font-size: 14px;
        line-height: 14px;
        color: #ef3819;
        cursor: pointer;
    }
    .cell_title {
        font-size: 16px;
        color: #1f81f8;
        cursor: pointer;
        text-decoration: underline #1f81f8;
    }
    .cell_link {
        height: 25px;
        margin-top: 11px;
        font-size: 14px;
        color: #999;
        white-space: nowrap;
        .link {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: var(--max-width);
        }
        .platform {
            font-size: 12px;
            color: #7dacfa;
            padding: 3px 4px;
            border: 1px solid #7dacfa;
            margin-left: 13px;
            position: relative;
            bottom: 6px;
        }
    }
    .platform {
        font-size: 12px;
        color: #7dacfa;
        padding: 3px 4px;
        border: 1px solid #7dacfa;
        margin-left: 13px;
        position: relative;
        bottom: 6px;
    }
    .two_line {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    .search_box {
        width: 100%;
        background-color: #ffffff;
        border-radius: 5px;
        border: solid 1px #ebeff2;
        box-sizing: border-box;
        margin-top: 59px;
        overflow: hidden;
        .input_box {
            display: flex;
            align-items: center;
            height: 80px;
            border-bottom: solid 1px #ebeff2;
            padding-left: 23px;
            position: relative;
            .input_shadow {
                width: 713px;
                height: 48px;
                background-color: #f0f5f9;
                border-radius: 6px;
                .el-input {
                    width: 705px;
                    height: 40px;
                    background-color: #ffffff;
                    border-radius: 6px;
                    margin: 4px;
                }
            }
            .empty_input {
                width: 186px;
                height: 44px;
                background-color: #fffaf7;
                box-shadow: 0 4px 10px 0 rgba(204, 204, 204, 0.3);
                border-radius: 6px;
                border: solid 1px #ffd1b5;
                display: flex;
                align-items: center;
                position: absolute;
                top: 60px;
                left: 27px;
                img {
                    height: 14px;
                    width: 14px;
                    margin-left: 16px;
                    margin-right: 10px;
                }
                span {
                    font-size: 14px;
                    color: #333333;
                }
            }
            .search_history {
                width: 661px;
                background-color: #ffffff;
                box-shadow: 0px 4px 10px 0px rgba(204, 204, 204, 0.3);
                border-radius: 6px;
                border: solid 1px #ebeff2;
                position: absolute;
                left: 25px;
                top: 60px;
                max-height: 174px;
                overflow-y: auto;
                z-index: 10;
                &::-webkit-scrollbar {
                    display: none;
                }
                .search_item {
                    height: 32px;
                    font-size: 14px;
                    color: #333333;
                    padding-left: 15px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    &:hover {
                        background-color: #e8f2fe;
                        color: #1f81f8;
                    }
                }
            }
        }
        .selections {
            padding: 25px 20px;
            background-color: #fff;
            .select_item {
                font-size: 14px;
                color: #333333;
                .title {
                    color: #999999;
                    margin-right: 27px;
                }
                .option {
                    margin-right: 40px;
                    cursor: pointer;
                    .count {
                        display: none;
                        color: #999999;
                        margin-left: 5px;
                    }
                }
                .selected {
                    color: #1f81f8;
                    .count {
                        color: #1f81f8;
                    }
                }
            }
        }
        .select_line {
            display: flex;
            padding: 23px 20px;
            .select_label {
                display: inline-block;
                color: #999999;
                font-size: 14px;
            }
        }
    }
    .search_list {
        background-color: #ffffff;
        border-radius: 5px;
        border: solid 1px #ebeff2;
        margin-top: 10px;
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            .cnt {
                font-size: 14px;
                color: #999999;
            }
        }
    }
}
</style>

<style lang="scss">
    .find_word .el-input-group__append, .el-input-group__prepend  {
        padding: 0;
        background-color: #ffffff;
        cursor: pointer;
        &:hover {
            background-color: #e9f3fd;
        }
        &:active {
            background-color: #f6f9fe;
        }
        .box {
            width: 46px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .find_word .el-table {
        td .cell {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
        .empty_box {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                margin-top: 20%;
            }
        }
        a:link {
            color: #1f81f8;
        }
        a:visited {
            color: #999999;
        }
    }
    .find_word .search_list .el-loading-spinner {
        top: 150px;
    }
</style>
